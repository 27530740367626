<template>
  <div v-if="store.companySettings.extendedShopSettings?.isOnlineShopActive">
    <DynamicDialog />
    <Sidebar
      v-model:visible="sideAddressShow"
      header="Sidebar"
      position="right"
      class="w-11 h-10 lg:h-10 md:w-8 lg:w-4 p-3">
      <template #container="{ closeCallback }">
        <div class="field w-full">
          <div>
            <Button
              type="button"
              @click="closeCallback"
              icon="pi pi-arrow-left"
              label="Zurück"
              outlined
              class="text-color"></Button>
          </div>
        </div>
        <AdressAuswahl />
      </template>
    </Sidebar>
    <Dialog
      v-model:visible="showImpressumDialog"
      modal
      header="Impressum"
      :style="{ width: '50vw' }"
      :breakpoints="{ '960px': '75vw', '641px': '100vw' }">
      <div v-html="store.companySettings.impressum"></div>
    </Dialog>
    <div
      v-if="!store.companySettings.slug || store.companySettings.slug == ''">
      Direkter Aufruf der App nicht möglich.
    </div>
    <div v-else>
      <ConfirmDialog />
      <Toast />
      <TheLogin
        :visible="showLogin"
        @hide="hideLogin()"
        @updateCategory="updateCategory()" />
      <RegisterComponent :visible="showRegister" @hide="showRegister = false" />
      <Dialog
        v-model:visible="showFillUserData"
        header="Daten Vervollständigen"
        modal
        style="width: 35vw"
        :breakpoints="{
          '960px': '55vw',
          '640px': '75vw',
        }">
        <FillUserDataForm @hide="showFillUserData = false" />
      </Dialog>

      <div id="app" class="app">
        <div style="height: auto">
          <div
            style="background-color: #000"
            class="px-3 sm:px-6 py-3 flex align-items-stretch justify-content-center sm:justify-content-end border-bottom-1 surface-border text-sm">
            <span v-if="!store?.userData?.customerId">
              <a
                class="text-white font-medium inline-flex align-items-center cursor-pointer px-3 hover:text-green-200 p-ripple"
                @click="showLogin = true">
                <i class="pi pi-sign-in mr-2 sm:mr-3 text-sm text-white"></i>
                <span v-ripple>Anmelden</span>
              </a>
              <span class="text-white mx-1 sm:mx-2">|</span>
              <a
                class="text-white font-medium inline-flex align-items-center cursor-pointer px-3 hover:text-green-200 p-ripple"
                v-ripple
                >
                <i class="pi pi-user mr-2 sm:mr-3 text-sm text-white"></i>
                <span v-if="store.companySettings.allowRegister" @click="showRegister = true">Konto erstellen</span>
                <span v-else @click="infoNoneRegi">Konto erstellen</span></a>
            </span>
            <span v-else>
              <span
                class="text-white cursor-pointer"
                @click="toggleUserSettings">
                <i
                  class="pi pi-chevron-down mr-2 sm:mr-3 text-sm text-white"></i>
                  <template v-if="store.userData.firstName && store.userData.surName">
                    {{ store.userData.firstName + " " + store.userData.surName }}
                  </template>
                  <template v-else>
                    {{ store.emailAdress }}
                  </template>

                </span
              >
              <Button
                text
                size="small"
                class="text-xs p-0 m-0 text-white"
                icon="pi pi-user"
                @click="toggleUserSettings"
                aria-haspopup="true"
                aria-controls="overlay_menu" />
              <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
            </span>
          </div>

          <div
            class="surface-overlay px-3 sm:px-6 flex align-items-stretch relative"
            style="min-height: 80px">
            <Button
              icon="pi pi-bars text-4xl"
              ripple
              text
              class="text-gray-700 lg:hidden"
              id="menuButton"
              @click="toggleOPMobile($event)" />
            <div
              class="flex align-items-center justify-content-center cursor-pointer"
              @click="router.push('/home')">
              <img
                :src="
                  store.companySettings.logoUrl ||
                  require('@/assets/famtura_icon_fb.svg')
                "
                alt="Image"
                class="lg:mr-6 h-2rem sm:h-3rem" />
            </div>
            <div
              id="nav-1"
              class="surface-overlay hidden lg:flex absolute lg:static left-0 top-100 z-1 shadow-2 lg:shadow-none w-full lg:w-auto py-3 lg:py-0 align-items-center overflow-x-auto ">
                              <ul class="list-none p-0 m-0 flex flex-column lg:flex-row">
                <li
                  class="flex flex-column lg:flex-row"
                  v-for="(category, index) in store.companySettings.categories
                    .list"
                  :key="index">
                  <a
                    class="inline-flex align-items-center cursor-pointer border-right-2 lg:border-right-none lg:border-bottom-2 border-transparent hover:border-primary py-3 lg:py-0 px-6 lg:px-3 text-700 select-none text-xl lg:text-base font-medium lg:font-base w-full lg:w-auto p-ripple"
                    v-ripple
                    data-pd-styleclass="true"
                    @click="chooseCategory($event, index, category)"
                    aria-haspopup="true"
                    aria-controls="overlay_panel">
                    <span>{{ category.label }}</span>
                  </a>
                  <OverlayPanel
                    unstyled
                    :ref="(el) => (overlayPanels[index] = el)"
                    :class="
                      category.children.length < 5
                        ? 'w-' + (category.children.length + 3)
                        : 'w-10'
                    "
                    appendTo="body">
                    {{ console.log("category", category) }}
                    <div
                      class="surface-overlay shadow-none lg:shadow-2 lg:absolute w-full left-0 pl-8 pr-6 lg:px-6 py-0 lg:py-6">
                      <ScrollPanel
                        style="width: 100%; height: auto"
                        class="p-0 m-0"
                        :step="10">
                        <div
                          class="flex flex-column lg:flex-row lg:justify-content-between">
                          <div
                            v-for="(subcategory, subIndex) in category.children"
                            :key="`sub-${subIndex}`">
                            <a
                              class="font-medium text-700 text-lg cursor-pointer block lg:hidden mb-1 select-none"
                              data-pd-styleclass="true"
                              @click="
                                chooseCategory($event, index, subcategory)
                              "
                              > {{ subcategory.label }}</a
                            >
                            <ul
                              class="list-none py-0 pr-0 pl-5 m-0 text-700 lg:block lg:pl-2">
                              <li
                                class="font-bold mb-3 text-lg  lg:block">
                                <a
                                  class="cursor-pointer"
                                  data-pd-styleclass="true"
                                  @click="
                                    chooseCategory($event, index, subcategory)
                                  "
                                  ><u> {{ subcategory.label }}</u></a
                                >
                              </li>
                              <li
                                class="pl-2 mb-1 cursor-pointer hover:text-900"
                                v-for="(
                                  subcategory2, sub2Index
                                ) in subcategory.children"
                                :key="`sub2-${sub2Index}`">
                                <a
                                  class="cursor-pointer"
                                  data-pd-styleclass="true"
                                  @click="
                                    chooseCategory($event, index, subcategory2)
                                  ">
                                   <u>{{ subcategory2.label }}</u></a
                                >
                              </li>
                            </ul>
                          </div>
                          <ul class="list-none p-0 m-0 text-700" v-if="(store.companySettings.showNewProducts || store.companySettings.showDiscountProduct)">
                            <li v-if="store.companySettings.showNewProducts"
                              class="mt-5 sm:mt-0 mb-5 flex flex-column align-items-center cursor-pointer"
                              @click="
                                handleNewProducts($event, index, category)
                              ">
                              <img
                                :src="
                                  category.newProductImageUrl ??
                                  require('@/assets/newProducts.jpg')
                                "
                                alt="Image"
                                class="w-15rem"
                                style="
                                  border-radius: 12px;
                                  object-fit: contain;
                                " /><span
                                class="inline-flex surface-0 text-900 px-3 py-2 border-round -mt-5 font-medium"
                                >Neue Produkte</span
                              >
                            </li>
                            <li v-if="store.companySettings.showDiscountProduct"
                              class="mb-5 flex flex-column align-items-center cursor-pointer"
                              @click="handleDiscounts($event, index, category)">
                              <img
                                :src="
                                  category.discountImageUrl ??
                                  require('@/assets/discounts.jpg')
                                "
                                alt="Image"
                                class="w-15rem"
                                style="
                                  border-radius: 12px;
                                  object-fit: contain;
                                " /><span
                                class="inline-flex surface-0 text-900 px-3 py-2 border-round -mt-5 font-medium"
                                >Rabatte</span
                              >
                            </li>
                          </ul>
                        </div>
                      </ScrollPanel>
                    </div>
                  </OverlayPanel>
                </li>
                <OverlayPanel
                  unstyled
                  ref="overlayPanelMobile"
                  class="w-full"
                  appendTo="#mo">
                  <Button
                    icon="pi pi-times text-2xl"
                    text
                    class="text-white font-bold"
                    @click="toggleOPMobile($event)" />
                  <Accordion
                    :activeIndex="0"
                    expandIcon="pi pi-plus"
                    collapseIcon="pi pi-minus">
                    <AccordionTab
                      v-for="(category, index) in store.companySettings
                        .categories.list"
                      :key="index">
                      <template #header>
                        <span class="flex align-items-center w-full">
                          <span
                            class="inline-flex align-items-center cursor-pointer border-right-2 border-transparent py-3 text-700 select-none text-xl"
                            >{{ category.label }}</span
                          >
                        </span>
                      </template>
                      <div
                        v-for="(subcategory, subIndex) in category.children"
                        :key="`sub-${subIndex}`"
                        class="surface-overlay shadow-none w-full left-0 pl-8 pr-6 py-0">
                        <div class="flex flex-column">
                          <a
                            class="font-medium text-700 text-lg cursor-pointer block mb-3 select-none"
                            @click="
                              chooseCategory(
                                $event,
                                subIndex,
                                subcategory,
                                true
                              )
                            "
                            >{{ subcategory.label }}</a
                          >
                          <ul class="pr-0 text-700 block">
                            <li
                              v-for="(
                                subcategory2, sub2Index
                              ) in subcategory.children"
                              :key="`sub2-${sub2Index}`"
                              class="mb-3 cursor-pointer hover:text-900">
                              <a
                                class="cursor-pointer"
                                @click="
                                  chooseCategory(
                                    $event,
                                    sub2Index,
                                    subcategory2,
                                    true
                                  )
                                "
                                >{{ subcategory2.label }}</a
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                      <ul class="list-none p-0 m-0 text-700" v-if="store.companySettings.showNewProducts || store.companySettings.showDiscountProduct">
                        <li v-if="store.companySettings.showNewProducts"
                          class="mt-5 sm:mt-0 mb-5 flex flex-column align-items-center cursor-pointer"
                          @click="
                            handleNewProducts($event, index, category, true)
                          ">
                          <img
                            :src="
                              category.newProductImageUrl ??
                              require('@/assets/newProducts.jpg')
                            "
                            alt="Image"
                            class="w-15rem"
                            style="
                              border-radius: 12px;
                              object-fit: contain;
                            " /><span
                            class="inline-flex surface-0 text-900 px-3 py-2 border-round -mt-5 font-medium"
                            >Neue Produkte</span
                          >
                        </li>
                        <li v-if="store.companySettings.showDiscountProduct"
                          class="mb-5 flex flex-column align-items-center cursor-pointer"
                          @click="
                            handleDiscounts($event, index, category, true)
                          ">
                          <img
                            :src="
                              category.discountImageUrl ??
                              require('@/assets/discounts.jpg')
                            "
                            alt="Image"
                            class="w-15rem"
                            style="
                              border-radius: 12px;
                              object-fit: contain;
                            " /><span
                            class="inline-flex surface-0 text-900 px-3 py-2 border-round -mt-5 font-medium"
                            >Rabatte</span
                          >
                        </li>
                      </ul>
                    </AccordionTab>
                  </Accordion>
                </OverlayPanel>
              </ul>

            </div>

            <div class="flex ml-auto">
              <ul class="list-none p-0 m-0 flex">
                <li class="flex" @click="toggleSuche">
                  <a 
                    class="text-900 font-medium inline-flex align-items-center cursor-pointer px-2 sm:px-3 hover:text-primary p-ripple"
                    data-pd-ripple="true"
                    >
                    <Button icon="pi pi-search" text class="p-0 m-0" :loading="loadingSuche"/>
                   <span class="hidden lg:inline" v-if="!suchFeld">Suche</span
                    ></a>
                </li>
                <li v-if="suchFeld" class="flex align-items-center border-none">
                      <InputText
                        v-model="suche"
                        placeholder="Suche"
                        @keydown.enter="searchProducts"
                        class="w-20rem bg-white-alpha-20 border-none p-3 text-primary-500 h-3-rem"
                        autofocus
                      />
                    </li>
                <li v-if="store.loggedIN" class="text-900 font-medium inline-flex align-items-center cursor-pointer px-2 sm:px-3 hover:text-primary p-ripple" @click="goToFavorites"><i
                      class="pi pi-heart mr-2 lg:mr-3 text-xl sm:text-base"></i
                    ><span class="hidden lg:inline" >Favoriten</span
                    >
                </li>
                <li v-if="store.loggedIN" class="flex">
                  <a
                    class="text-900 font-medium inline-flex align-items-center cursor-pointer px-2 sm:px-3 hover:text-primary p-ripple"
                    data-pd-ripple="true"
                    @click="displayShoppingCart = true"
                    ><i
                      class="pi pi-shopping-cart lg:mr-3 text-xl sm:text-base"
                      ><span
                        class="p-badge p-component p-badge-dot"></span></i
                    ><span class="hidden lg:inline">Warenkorb</span
                    ></a>
                </li>
                <li v-else class="text-900 font-medium inline-flex align-items-center cursor-pointer px-2 sm:px-3 hover:text-primary p-ripple" @click="showLogin = true">
                 <i
                      class="pi pi-shopping-cart lg:mr-3 text-xl sm:text-base p-overlay-badge"
                      data-pd-badge="true"
                      data-p-overlay-badge="true"
                      ></i
                    ><span class="hidden lg:inline">Warenkorb</span
                    >
                </li>
              </ul>
            </div>
          </div>

          <!--sidebar Warenkorb-->
          <div id="warenkorb">
            <Sidebar
              v-model:visible="displayShoppingCart"
              position="right"
              style="width: 35rem">
              <div
                class="surface-overlay p-4 flex align-items-center justify-content-between">
                <span class="text-900 text-xl font-medium">Dein Warenkorb</span>
              </div>
              <ShoppingCart />
              <template v-if="store.shoppingCart && store.shoppingCart.length > 0">
                <Button
                class="w-full mt-4"
                ripple="true"
                severity="danger"
                icon="pi pi-trash"
                label="Warenkorb leeren"
                @click="confirmClearShoppingCart()" />
                <Button
                class="w-full mt-4"
                ripple="true"
                icon="pi pi-shopping-cart"
                :disabled="!store.loggedIN"
                label="Bestellung aufgeben"
                @click="confirmOrder()" />
              </template>
            </Sidebar>
          </div>
          <span id="mo" class="p-0 m-0"></span>

          <!--sidebar Warenkorb-->
          <!--footer-->
          <div
            class="px-4 py-0 md:px-6 lg:px-8 flex flex-wrap justify-between align-items-center"
            style="
              position: fixed;
              bottom: 0;
              width: 100%;
              background-color: #000;
              z-index: 10;
            ">
            <div
              class="w-full md:w-auto flex justify-between md:justify-start mb-3 md:mb-0">
              <img
                :src="
                  store.companySettings.logoUrl ||
                  require('@/assets/famtura_icon_fb.svg')
                "
                alt="Image"
                class="w-2rem md:w-20rem h-2rem md:h-2rem"
                style="object-fit: contain" />
            </div>
            <!--           <div
            class="w-full md:w-auto text-white text-lg mb-4 flex flex-wrap align-items-center">
            <div class="w-full md:w-auto">
              {{ store.companySettings.cname }}
              <Divider layout="vertical" class="hidden md:inline" />
            </div>
            <div class="w-full md:w-auto">
              {{ store.companySettings.streetName }}
              {{ store.companySettings.houseNumber }}
            </div>
            <div class="w-full md:w-auto">
              {{ store.companySettings.postCode }}
              {{ store.companySettings.city }}
            </div>
            <Divider layout="vertical" class="hidden md:inline" />
            <i class="pi pi-phone border-round p-1 mr-2"></i
            >{{ store.companySettings.phone }}
            <Divider layout="vertical" class="hidden md:inline" />
            <i class="pi pi-inbox border-round p-1 mr-2"></i
            >{{ store.companySettings.email }}
            <Divider layout="vertical" class="hidden md:inline" />
          </div> -->
            <div
              class="w-full md:w-auto text-gray-200 flex-wrap">
              <a
                class="line-height-3 block cursor-pointer"
                @click="showImpressumDialog = true"
                >Impressum</a
              >
            </div>
            <div class="w-full md:w-auto text-gray-200">
              <!-- <div class="text-white font-bold line-height-3 mb-3">Titel</div> -->
              <!-- <a class="line-height-3 block cursor-pointer mb-2">Neuigkeiten</a> -->
            </div>
          </div>

          <!--footer-->
        </div>
        <div
          v-if="store.isAuthenticated && !store?.userData.firstLogin"
          class="content"
          :style="{ 'margin-left': isMobile ? '0' : '0' }">
          <router-view></router-view>
        </div>
        <div v-else class="content">
          <router-view></router-view>
        </div>
        <!-- Hauptinhalt der Anwendung -->
      </div>
    </div>
  </div>
  <div v-else>
    <div class="flex align-items-center justify-content-center h-full">
      <div class="text-center">
        <h1 class="text-4xl font-bold">Fehler</h1>
        <p class="text-2xl">Die App ist nicht konfiguriert.</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, provide, onUnmounted,watch } from "vue";

import { userStore } from "@/store/userStore";
import { useRouter, useRoute } from "vue-router";
import { useConfirm } from "primevue/useconfirm";
import { useI18n } from "vue-i18n";
import "material-symbols/outlined.css";
import { useToast } from "primevue/usetoast";
import TheLogin from "./components/auth/TheLogin.vue";
import RegisterComponent from "./components/auth/RegisterComponent.vue";
import InvoicesView from "./views/InvoicesView.vue";
import iAxios from "./store/axiosInterface";
import FillUserDataForm from "@/components/auth/FillUserDataForm.vue";
import { clearShoppingCart } from "@/utils/shoppingCartUtils";
import { useDialog } from "primevue/usedialog";
import ShoppingCart from "./views/ShoppingCart.vue";
import AdressAuswahl from "./views/AdressAuswahl.vue";



const dialog = useDialog();
const sideAddressShow = ref(false);
//const categories = store.companySettings.categories.list;
const showImpressumDialog = ref(false);
const displayShoppingCart = ref(false);
const toast = useToast();
const { locale } = useI18n();
const confirm = useConfirm();
const showRegister = ref(false);
const showLogin = ref(false);
const showFillUserData = ref(false);
const store = userStore();
const tipEnabledList = store.tipEnabledList;
const suchFeld = ref();
const suche = ref();
const router = useRouter();
const route = useRoute();
const loadingSuche = ref(false);


const isSidebarExpanded = ref(false);
const isMobile = ref(false);
const expandedKeys = ref([]);
const isSidebarCollapsed = ref(false);
const MenuVisible = ref(false);
const holdSidebar = ref(false);
let dbgZlr = 0;
const selectedCategory = ref();

const breadcrumbState = reactive({
  breadcrumbItems: [],
});
provide("breadcrumbState", breadcrumbState);
provide("showLogin", showLogin);

const whoAmI = () => {
  console.log("WHOAMI");
  let url = "/auth/customer/whoami";
  iAxios.get(url).then((response) => {
    console.log("🚀 ~ file: App.vue:139 ~ response:", response);
  });
  url = "/auth/customer/itemlist";
  iAxios.get(url).then((response) => {
    console.log("🚀 ~ file: App.vue:142 ~ response:", response);
  });
};

const menu = ref();
const items = ref([
  {
    label: "Options",
    items: [
      {
        label: "Daten ändern",
        icon: "pi pi-user-edit",
        command: () => {
          showFillUserData.value = true;
        },
      },
      {
        label: "Adressen hinzufügen",
        icon: "pi pi-plus",
        command: () => {
          openAddressDialog();
        },
      },
      {
        label: "Rechnungen",
        icon: "pi pi-credit-card",
        command: () => {
          showInvoices();
        },
      },
      {
        label: "Bestellungen",
        icon: "pi pi-book",
        command: () => {
          router.push("/orderHistory");
        },
      },
      { separator: true },
      {
        label: "Abmelden",
        icon: "pi pi-sign-out",
        command: (event) => {
          confirmLogOut(event);
        },
      },
    ],
  },
]);

const toggleUserSettings = (event) => {
  menu.value.toggle(event);
};

const updateCategory = () => {
  console.log("🚀 ~ file: App.vue:186 ~ updateCategory ~ updateCategory");
  chooseCategory(null, null, selectedCategory.value);
};

const handleDebug = () => {
  dbgZlr++;
  if (dbgZlr > 2) {
    dbgZlr = 0;
    store.setDebug(true);
    toast.add({
      severity: "info",
      summary: "DEBUG MODUS",
      detail: "DEBUG MODUS AKTIVIERT",
      life: 3000,
    });
  }
};

const doClearShoppingCart = async () => {
  await clearShoppingCart();
}
 

const infoNoneRegi = () => {
  toast.add({
    severity: "info",
    summary: "Registrierung",
    detail: "Bitte wenden Sie sich direkt an "+store.companySettings.cname+" um ein Konto zu erstellen.",
    life: 3000,
  });
};

const hideLogin = () => {
  showLogin.value = false;
};

const confirmLogOut = (data, draftToInvoice) => {
  let msg = "Bestätigen Sie die Abmeldung?";
  confirm.require({
    message: msg,
    header: "Abmelden bestätigen",
    icon: "pi pi-info-circle",
    acceptClass: "p-button-danger",
    acceptLabel: "Ja",
    rejectLabel: "Nein",
    defaultFocus: "reject",
    accept: () => {
      logout();
    },
    reject: () => {},
  });
};

const setDebugOff = () => {
  store.setDebug(false);
  toast.add({
    severity: "warn",
    summary: "DEBUG MODUS",
    detail: "DEBUG MODUS DEAKTIVIERT",
    life: 3000,
  });
};

const toggleSuche = () => {
   suchFeld.value = !suchFeld.value;
};

const logout = () => {
  try {
    store.logout();
    MenuVisible.value = false;
    chooseCategory(null, null, selectedCategory.value);
    //router.push("/" + store.companySettings.slug);
  } catch (e) {
    console.log("\n\n logout error", e);
  }
};



const mobileMenu = () => {
  MenuVisible.value = !MenuVisible.value;
  if (MenuVisible.value === true) {
    const mask = document.querySelector(".p-sidebar-mask");
    const overlayClass = "p-component-overlay-enter";
    if (mask && mask.classList.contains(overlayClass)) {
      mask.classList.remove(overlayClass);
    }
  }
};

/* const toggleSidebar = () => {
  if (isMobile.value) {
    isSidebarCollapsed.value = !isSidebarCollapsed.value;
  }
}; */

//watchEffect(() => {
/*   if (currentRoute.name=="MailConfirm"){
  console.log("🚀 ~ file: App.vue:306 ~ watchEffect ~ currentRoute.name:", currentRoute.name)
  console.log("currentRoute=MailConfirm");
}else{
  console.log("🚀 ~ file: App.vue:306 ~ watchEffect ~ currentRoute.name:", currentRoute.name)
  console.log("currentRoute!=MailConfirm");
}

if (store.isAuthenticated==false && currentRoute.name!="login" && currentRoute.name!="MailConfirm") {
  //router.push('/');
} */
//});

const expandSidebar = () => {
  if (holdSidebar) isSidebarExpanded.value = true;
};

const collapseSidebar = () => {
  if (holdSidebar) isSidebarExpanded.value = false;
};

const handleMenuCommand = (event) => {
  // Aktion nach dem Betätigen eines Menüpunkts
  // Hier die Sidebar verkleinern
  collapseSidebar();
};

const checkMobile = () => {
  isMobile.value = window.innerWidth <= 768; // Anpassen der Bildschirmbreite nach Bedarf

  if (isMobile.value) {
    isSidebarExpanded.value = false; // Sidebar ausblenden auf mobilen Geräten
  }
};

const goToFavorites = async() => {
const favorites=await store.getFavorites;

  console.log("🚀 ~ file: App.vue:770 ~ favorites:", favorites)

  if (!favorites || favorites?.length == 0) {
    toast.add({
      severity: "info",
      summary: "Favoriten",
      detail: "Keine Favoriten vorhanden",
      life: 3000,
    });
    return;
  };
  loadingSuche.value = true;
  let url = "/customer/itemlist";
  if (store.loggedIN) url = "/auth/customer/itemlist";
  iAxios.get(url).then((response) => {

    console.log("🚀 ~ file: App.vue:784 ~ response:", response)

    // Extrahieren der item_id_company aus den Favoriten für den Vergleich
    const favoriteItemIds = favorites.map(fav => fav.item_id_company);

    const gefilterteProdukte = response.data.itemList.filter(
      (item) => favoriteItemIds.includes(item.item_id_company) // Vergleich der item_id_company
    );
    store.products = gefilterteProdukte;
    setTimeout(() => {
    loadingSuche.value = false;
  }, 10);
  });
  router.push("/category");

};

const searchProducts = () => { 
  loadingSuche.value = true;
  //Filtern der Produkte nach Suchbegriff un speichern in store.products und aufruf von /category
  let url = "/customer/itemlist";
  if (store.loggedIN) url += "/auth";
  iAxios.get(url).then((response) => {
    //ergebnis Filtern nach itemname und ean
    const gefilterteProdukte = response.data.itemList.filter(
      (item) =>
        item.itemName?.toLowerCase().includes(suche.value.toLowerCase()) ||
        item?.ean?.toLowerCase().includes(suche.value.toLowerCase())
    );
    store.products = gefilterteProdukte;
    setTimeout(() => {
    loadingSuche.value = false;
  }, 10);
  });
  router.push("/category");

};

onMounted(async () => {
  store.getCompanySettings;
  //console.log(store.whoAmI);
  console.log(store.loggedIN);
  if (!store.shoppingCart || !store.shoppingCart.length) {
    store.shoppingCart = store.loadShoppingCart();
    console.log(store.shoppingCart);
  }
  let url = "/auth/customer/itemlist";
  iAxios.get(url).then((response) => {
    store.itemList = [];

    response.data.itemList.forEach(o => {
      store.itemList[o.item_id_company] = o;
    });
    console.log(store.itemList);
  }).catch(e => console.log(e));

  locale.value = "de";
  checkMobile();
});

const handleNewProducts = (event, index, category, isMobile) => {
  console.log("🚀 ~ file: App.vue:801 ~ event:", event);

  console.log("🚀 ~ file: App.vue:801 ~ category:", category);

  if (!category) {
    console.log("🚀 ~ file: App.vue: ~ keine Kategorie:");
    router.push("/category");
    return;
  }
  let url =
    "/customer/itemlist-new/" + store.companySettings.cid + "/" + category.key;

  if (store.loggedIN) {
    url = "/auth/customer/itemlist-new/" + category.key;
  }
  iAxios.get(url).then((response) => {
    console.log("🚀 ~ file: App.vue:82 ~ response:", response);
    store.products = response.data.itemList;
  });

  if (event) {
    if (!isMobile) {
      toggleOP(event, index);
    } else {
      toggleOPMobile(event);
    }
  }
  router.push("/category");
  const fullPath = findFullPath(
    store.companySettings.categories.list,
    category.key
  );
  UpdateBreadcrumbItems(fullPath);
};

const handleDiscounts = (event, index, category, isMobile) => {
  if (!category) {
    console.log("🚀 ~ file: App.vue:649 ~ keine Kategorie:");
    router.push("/category");
    return;
  }
  let url =
    "/customer/itemlist-discount/" +
    store.companySettings.cid +
    "/" +
    category.key;

  if (store.loggedIN) {
    url = "/auth/customer/itemlist-discount/" + category.key;
  }
  iAxios.get(url).then((response) => {
    console.log("🚀 ~ file: App.vue:82 ~ response:", response);
    store.products = response.data.itemList;
  });

  if (event) {
    if (!isMobile) {
      toggleOP(event, index);
    } else {
      toggleOPMobile(event);
    }
  }
  router.push("/category");
  const fullPath = findFullPath(
    store.companySettings.categories.list,
    category.key
  );
  UpdateBreadcrumbItems(fullPath);
};

const chooseCategory = (event, index, category, isMobile) => {
  console.log("🚀 ~ file: App.vue:649 ~ store:", store);
  selectedCategory.value = category;
  store.currentCategory = category;

  console.log("🚀 ~ file: App.vue:916 ~ selectedCategory.value:", selectedCategory.value)


  if (!category) {
    console.log("🚀 ~ file: App.vue:649 ~ keine Kategorie:");
    router.push("/home");
    return;
  }

  let url =
    "/customer/itemlist/" + store.companySettings.cid + "/" + category.key;
  if (store.loggedIN) {
    url = "/auth/customer/itemlist/" + category.key;
  }
  iAxios.get(url).then((response) => {
    store.products = response.data.itemList;
  });

  if (event && (category?.children?.length>0 || store.companySettings.showNewProducts || store.companySettings.showDiscountProduct)) {
    if (!isMobile) {
      toggleOP(event, index);
    } else {
      toggleOPMobile(event);
    }
  }
  router.push("/category");
  const fullPath = findFullPath(
    store.companySettings.categories.list,
    category.key
  );
  UpdateBreadcrumbItems(fullPath, index);
};

const UpdateBreadcrumbItems = (fullPath, index) => {
  breadcrumbState.breadcrumbItems = fullPath.map((cat) => ({
    label: cat.label,
    key: cat.key,
    command: () => chooseCategory(null, index, cat),
  }));
};

function findFullPath(categories, key) {
  for (const category of categories) {
    if (category.key === key) {
      return [category];
    }
    if (category.children) {
      const path = findFullPath(category.children, key);
      if (path) {
        return [category, ...path];
      }
    }
  }
  return null;
}

const overlayPanels = {};
const overlayPanelMobile = ref();

const toggleOP = (event, index) => {
  overlayPanels[index].toggle(event);
};

const toggleOPMobile = (event) => {
  console.log(
    "🚀 ~ file: App.vue:883 ~ overlayPanelMobile.value:",
    overlayPanelMobile.value
  );
  overlayPanelMobile.value.toggle(event);
};

const confirmOrder = async () => {
  displayShoppingCart.value = false;
  router.push("/checkout");
};

const confirmClearShoppingCart = () => {
  confirm.require({
    message: "Warenkorb leeren?",
    header: "Warenkorb leeren",
    icon: "pi pi-info-circle",
    acceptClass: "p-button-danger",
    acceptLabel: "Ja",
    rejectLabel: "Nein",
    defaultFocus: "reject",
    accept: async () => {
      await clearShoppingCart();

      console.log(
        "🚀 ~ file: App.vue:812 ~  store.shoppingCart:",
        store.shoppingCart
      );

      displayShoppingCart.value = false;
    },
    reject: () => {},
  });
};

const openAddressDialog = () => {
  sideAddressShow.value = true;
};

const showInvoices = () => {
  const dialogRef = dialog.open(InvoicesView, {
    props: {
      header: "Rechnungen Anzeigen",
      style: {
        width: "35vw",
      },
      breakpoints: {
        // breakpoints so lassen! bei fragen an abdullah
        "2135px": "48vw",
        "1800px": "50vw",
        "1600px": "60vw",
        "1460px": "70vw",
        "1336px": "75vw",
        "1000px": "90vw",
        "831px": "100vw",
      },
      modal: true,
      closable: true,
    },
    onClose: async (options) => {
      if (options.data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "Save") {
          let responseFile = await saveAndGetPDF(dialogRef);
          pdfFile.value = dialogRef.data.pdfFile;
        } else if (buttonType == "Generate") {
          let response = speichern(dialogRef, true);
        } else if (buttonType == "Delete") {
          //von virtalInvoices löschen mit invoiceDocumentId
          refreshReRenderTable();
          /*           const index = virtualInvoices.value.findIndex((invoice) => invoice.invoiceDocumentId == options.data.invoiceDocumentId);
                    if (index > -1) {
                      virtualInvoices.value.splice(index, 1);
                    }
                    //von invoices löschen mit invoiceDocumentId
                    const index2 = invoices.value.findIndex((invoice) => invoice.invoiceDocumentId == options.data.invoiceDocumentId);
                    if (index2 > -1) {
                      invoices.value.splice(index2, 1);
                    } */
        } else if (buttonType == "GoToInvoice") {
          openInvoice(options.data.invoiceDocumentId);
        } else refreshReRenderTable();
      }
    },
  });
};
</script>

<style scoped>
.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden;
}

.header {
  position: fixed;
  background-color: #ffffff;
  padding-left: 7px;
  z-index: 2;
  height: 60px;
  width: 100%;
  transition: width 0.2s;
}

.scheinShadow {
  filter: drop-shadow(-0.9rem -0.05rem 0.07rem #282828);
  /*   filter: drop-shadow(0px 3px 1px rgba(255, 255, 255,0.6));
 */
}

.logo {
  height: 50px;
  width: auto;
  margin-bottom: 5px;
  margin-top: 5px;
}

.main-container {
  flex-grow: 1;
  display: flex;
  position: relative;
  overflow-x: hidden;
}

.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transition: width 0.3s ease;
  z-index: 2;
  top: 60px;
}

.sidebar {
  background-color: #fff;
  width: 70px;
  height: 100%;
  padding: 10px;
  transition: width 0.3s ease;
}

.sidebar-icon {
  height: 50px;
  width: auto;
  margin-bottom: 5px;
  margin-top: 5px;
}

.sidebar-icon-long {
  height: 50px;
  width: auto;
  margin-top: 5px;
  margin-bottom: 5px;
}

.sidebar-expanded {
  width: 220px;
}

:deep(.p-panelmenu .p-panelmenu-header) {
  width: 55 px;
}

:deep(.p-panelmenu .p-panelmenu-header .p-panelmenu-header-content) {
  background-color: #ffffff;
  background: #ffffff;
  border: 0;
}

:deep(
    .p-panelmenu
      .p-panelmenu-header
      .p-panelmenu-header-content
      .p-panelmenu-header-action
  ) {
  color: #000000;
  padding: 1.25rem;
  font-weight: 700;
}

:deep(
    .p-panelmenu
      .p-panelmenu-header
      .p-panelmenu-header-content
      .p-panelmenu-header-action
      .pi
  ) {
  font-size: 24px;
}

:deep(
    .p-panelmenu
      .p-panelmenu-header
      .p-panelmenu-header-content
      .p-panelmenu-header-action
      .pi
      .material-symbols-outlined
  ) {
  font-size: 32px;
}

:deep(.p-panelmenu .p-submenu-icon) {
  display: none !important;
}

/* @media (min-width: 768px) {
  :deep(.p-menuitem-text) {
    display: none;
  }
} */

:deep(.sidebar-expanded .p-menuitem-text) {
  display: inline;
  font-size: 12px;
  font-weight: 500;
}

:deep(.p-sidebar) {
  top: 62px;
  background: #944040 !important;
  background-color: #ad0707 !important;
}

.sidebar-expanded {
  width: 100%;
  /* Vollständige Breite der Sidebar */
}
</style>
