import { defineStore } from "pinia";
import iAxios from "./axiosInterface";
import { reactive } from "vue";

export const userStore = defineStore("userStore", {
  state: () => ({
    visitedCompanies: JSON.parse(localStorage.getItem('visitedCompanies')) || [],
    notReady: false,
    userData: {},
    headerTitel: "",
    userId: null,
    whoAmIData: {},
    loggedIN: false,
    expired: false,
    registrationConfirmed: false,
    firstLogin: null,
    debug: false,
    registersStayOpen: false,
    tipEnabledList: {},
    companySettings: {},
    holdSidebarOpen: false,
    products: [],
    itemsLoaded: false,
    emailAdress: null,
    shoppingCart: {},
    favorites: null,
    currentCategory: null,
    allItems: [],
    //expiresIn: "",
  }),


  getters: {
    getItems(state) {
      return state.items;
    },

    async getFavorites(state) {
      try {
        let url=process.env.VUE_APP_BASE_API_URL_AUTH_CUSTOMER + '/customer-details';
        const response = await iAxios.get(url);

        console.log("🚀 ~ file: userStore.js:41 ~ customer-details response.data:", response.data)

        state.favorites = response.data.customer.extended?.favorites || null;
        return state.favorites;
      }
      catch (e) {
        return null;
      }
    },

    async getCustomerDetails(state){
      try {
        let url=process.env.VUE_APP_BASE_API_URL_AUTH_CUSTOMER + '/customer-details';
        const response = await iAxios.get(url);
        return response.data.customer;
      }
      catch (e) {
        return null;
      }
    },
      

    getDebug(state) {
      return state.debug;
    },

    getWhoAmIData(state) {
      return state.whoAmIData;
    },

    getCategories(state) {
      return state.companySettings.categories.list || [];
    },
    getNotReady(state) {
      return state.notReady;
    },
    getCid(state) {
      return state.userId;
    },
    async whoAmI(state) {
      let url = "/auth/customer/whoami";
      try {
        const response = await iAxios.get(url);
        state.whoAmIData = await response.data;
        state.userId = response.data.cid;
        state.firstLogin = response.data.firstLogin;
        state.loggedIN = true;

        if (response.data.firstLogin) await iAxios.get('https://storage.famtura.de/api/auth/apikey/add');
        this.getCompanySettings;

        this.loadShoppingCart();

        return response.data;
      } catch (error) {
        console.log(error);
        state.userId = null;
        state.loggedIN = false;
        state.expired = true;
      }
    },

    async getCompanySettings(state) {
      const slug=state.companySettings.slug;
      let url = "/customer/company/" + slug;
      try {
        const response = await iAxios.get(url);
        state.companySettings = response.data;
        state.companySettings.slug = slug;
        return response.data;
      } catch (err) {
        console.error("Fehler beim Senden der Daten:", err);
      }
      return state.companySettings;
    },

    isAuthenticated(state) {
      return !!state.userId;
    },

    isFirstLogin(state) {
      return state.firstLogin;
    },
  },
  actions: {
    // Aktion zum Markieren eines Unternehmens als besucht
    visitCompany(companySlug) {
      if (!this.visitedCompanies.includes(companySlug)) {
        this.visitedCompanies.push(companySlug);
        // Optional: Persistierung im LocalStorage oder einer Datenbank
      }
    },

    setFavorites(favorites) {
      try{
        let url=process.env.VUE_APP_BASE_API_URL_AUTH_CUSTOMER + '/customer-details';
        const body={customerUid:this.userData.customerUid, extended: { favorites: favorites } };
        const response = iAxios.patch(url, body);
        this.favorites = favorites;
        return response;
      }
      catch (e) {
        return null;
      }
    },

    // Prüft, ob ein Unternehmen bereits besucht wurde
    hasVisited(companySlug) {
      return this.visitedCompanies.includes(companySlug);
    },
    setNotReady(bool) {
      this.notReady = bool;
    },
    setRegistrationConfirmed(bool) {
      this.registrationConfirmed = bool;
    },
    setDebug(bool) {
      this.debug = bool;
    },
    async setCategories(categories) {
      try {
        this.categories = { list: { categories: categories } };

        console.log("🚀 ~ file: userStore.js:100 ~ categories:", categories)

        const url = process.env.VUE_APP_BASE_API_URL + '/company/settings';
        const formData = { categories: { list: categories } };
        const res = await iAxios.put(url, formData);

        console.log("🚀 ~ file: userStore.js:103 ~ res:", res)

        return res;
      } catch (e) {
        console.error(e);
      }
    },

    // savepath="/.." 
    // responsetype='data' or default=root response
    // object=object to send (nullable) 
    // kind='patch','post' or 'get' default=get 
    /*     async deleteFromEp(deletePath) {
          return this.sendToEp(deletePath, null, "delete", null, null);
        },
        async sendToEp(savePath, object, saveKind, responseType, epKind) {
        console.log("🚀 ~ sendToEp ~ epKind:", epKind)
        console.log("🚀 ~ sendToEp ~ responseType:", responseType)
        console.log("🚀 ~ sendToEp ~ saveKind:", saveKind)
        console.log("🚀 ~ sendToEp ~ object:", object)
        console.log("🚀 ~ sendToEp ~ savePath:", savePath)
    
          let url = process.env.VUE_APP_BASE_API_URL + savePath;
          if (epKind == "customer") url = process.env.VUE_APP_BASE_API_URL_CUSTOMER + savePath;
          console.log("🚀 ~ sendToEp ~ url:", url)
          //let url=url1 + '?nocache=' + new Date().getTime(); // Safari fix
          try {
            if (object) {
              object.cid = this.userId;
            }
    
            let response = null;
            response =
            saveKind == 'post' ?
                await iAxios.post(url, object) :
                saveKind == 'patch' ?
                  await iAxios.patch(url, object) :
                  saveKind == 'delete' ?
                    await iAxios.delete(url) :
                    await iAxios.get(url, object);
                    if (responseType == 'data') return Promise.resolve(response.data);
                    else return response;
          } catch (error) {
            return error;
          }
        }, */

    async setUserData(userData) {
      this.userData = userData;
    },

    async setCompanySettings(companySettings) {
      this.companySettings = companySettings;
    },

    async resetCompanySettings() {
      this.companySettings = {};
    },

    async setCompanyTips(tipEnabledList) {
      try {
        let url = process.env.VUE_APP_BASE_API_URL + "/company/tips";
        const response = await iAxios.post(url, tipEnabledList);
        const tips = {};
        tips.tipsList = response.data;
        return tips;
      } catch (e) {
      }
    },

    async resetPassword(emailAddress4ResetPassword) {
      let url = process.env.VUE_APP_BASE_API_URL + "/reset-pass/" + emailAddress4ResetPassword+"/"+this.companySettings.cid;
      try {
        const response = await iAxios.get(url);
        if (response.status == 200) {
          return response;
        }
      } catch (error) {
        if (response.status == 403) {
          return response;
        }
      }
    },
    async logout() {
      let url = process.env.VUE_APP_BASE_API_URL_CUSTOMER + "/logout";
      try {
        this.userId = null;
        this.expired = true;
        this.loggedIN = false;
        this.userData = null;
        this.products = null;
        this.shoppingCart = null;
        await iAxios.get(url);
        //return response;
      } catch (error) {
        this.userId = null;
        this.expired = true;
        this.loggedIN = false;
        this.userData = null;
        this.products = null;
        this.shoppingCart = null;
      }
    },
    async auth(payload) {
      console.log("auth");
      let url = "";
      if (payload.mode === "signin") {
        url = process.env.VUE_APP_BASE_API_URL_CUSTOMER + "/login";
      } else if (payload.mode === "signup") {
        url = process.env.VUE_APP_BASE_API_URL_CUSTOMER + "/register";
      } else {
        return;
      }
      const authDO = {
        email: payload.email,
        password: payload.password,
        cid: payload.cid,
        slug: this.companySettings.slug,
      };
      this.emailAdress = payload.email;
      try {
        const response = await iAxios.post(url, authDO);
        this.userData = response.data;
        this.userId = response.data.cid;
        this.firstLogin = response.data.firstLogin;
        
        if (payload.mode !== "signup") this.whoAmIData = this.whoAmI;
        return response;
      } catch (error) {
        const errorMessage = new Error(
          error.response.data.message || "UNKNOWN_ERROR"
        );
        throw errorMessage;
      }
    },
    async signup(payload) { //Register user
      const signupDO = {
        ...payload,
        mode: "signup",
      };
      const response = await this.auth(signupDO);
      this.userId = response.data.cid;
      return response;
    },
    async signin(payload) { //Login
      const signinDO = {
        ...payload,
        mode: "signin",
      };
      const response = await this.auth(signinDO);
      this.userId = response.data.cid;
      this.expired = false;
      this.loggedIN = true;

      this.loadShoppingCart();

      this.firstLogin = response.data.firstLogin;

      console.log("🚀 ~ file: userStore.js:261 ~ response.data.firstLogin:", response.data.firstLogin)

      return response;
    },
    loadShoppingCart() {
      if (!this.loggedIN) {
        return [];
      }
      iAxios.get(process.env.VUE_APP_BASE_API_URL_AUTH_CUSTOMER + '/active-shoppingcart').then((response) => {
        let shoppingCart = [];
        response.data.shoppingCart.forEach(element => {
          let quantity = element.quantity;
          shoppingCart.push({ item: element, quantity: quantity });
  
          delete element.quantity;
        });

        this.shoppingCart = shoppingCart;
      }).catch((e) => {
        console.log(e);
      });
    },
    setExpired(bool) {
      this.expired = bool;
      if (bool == true) this.loggedIN = false;
      //console.clear();
    },
    setHeaderTitel(titelText) {
      this.headerTitel = titelText;
    },
    setItems(items) {
      console.log("setItems");
      this.items = JSON.parse(JSON.stringify(items));
      this.itemsLoaded = true;
    }
  },
  persist: true,
});
