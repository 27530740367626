<template>
  <Dialog
    :visible="visible"
    @update:visible="visible = $event"
    modal
    :pt="{
      root: 'border-none',
      mask: {
        style: 'backdrop-filter: blur(3px)',
      },
    }">
    <template #container="{ closeCallback }">
      <Form
        @submit="submitData"
        :validation-schema="schema"
        v-slot="{ errors }">
        <div
          class="flex flex-column px-5 py-5 gap-4"
          style="
            border-radius: 12px;
            background-image: radial-gradient(
              circle at top,
              var(--gray-200),
              var(--gray-900)
            );
          ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xml:space="preserve"
            width="60"
            height="60"
            class="block mx-auto shine-effect"
            style="
              shape-rendering: geometricPrecision;
              text-rendering: geometricPrecision;
              image-rendering: optimizeQuality;
              fill-rule: evenodd;
              clip-rule: evenodd;
            "
            viewBox="0 0 1735.67 1724.274">
            <defs>
              <filter id="f2" x="0" y="0" width="200%" height="200%">
                <feOffset result="offOut" in="SourceGraphic" dx="20" dy="20" />
                <feGaussianBlur
                  result="blurOut"
                  in="offOut"
                  stdDeviation="10" />
                <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
              </filter>
            </defs>
            <path
              d="M.09 6.007c1.413 3.534 42.753 86.214 91.867 184.087l89.04 177.374 685.823-.707 685.823-1.06 91.513-180.201c50.173-99.288 91.513-181.261 91.513-183.028 0-1.413-391.142-2.473-869.203-2.473C40.37-.001-2.384.353.089 6.005v.001zm237.441 469.935c0 2.12 52.293 106.354 443.789 882.63 87.274 172.78 164.301 325.775 171.014 339.908 6.714 14.133 13.427 25.794 15.193 25.794 1.413 0 2.473-216.241 2.473-480.536V763.202h482.301l73.494-144.867 73.847-144.867H868.586c-346.975 0-631.056 1.06-631.056 2.473l.001.001z"
              style="fill: #099; fill-rule: nonzero" />
          </svg>
          <div class="inline-flex flex-column gap-2">
            <label for="username" class="text-primary-50 font-semibold"
              >Benutzername</label
            >
            <InputText
              v-model="form.email"
              placeholder="xyz@email.com"
              id="username"
              autocomplete="username webauthn"
              class="bg-white-alpha-20 border-none p-3 text-primary-50 w-20rem"></InputText>
          </div>
          <div class="inline-flex flex-column gap-2">
            <label for="password" class="text-primary-50 font-semibold"
              >Passwort</label
            >
            <InputText
              v-model="form.password"
              id="password"
              class="bg-white-alpha-20 border-none p-3 text-primary-50 w-20rem"
              type="password"
              autocomplete="current-password webauthn">
            </InputText>
            <Button text label="Passwort vergessen" @click="resetPass" class="text-gray-400"/>
          </div>
          <div class="flex align-items-center gap-3">
            <Button
              label="Abbrechen"
              @click="emit('hide')"
              text
              class="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"></Button>
            <Button
              label="Anmelden"
              @click="submitData"
              text
              class="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10"></Button>
          </div>
        </div>
      </Form>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="showFillUserData"
    header="Daten Vervollständigen"
    modal
    style="width: 35vw"
    :breakpoints="{
      '960px': '55vw',
      '640px': '75vw',
    }">
    <FillUserDataForm @hide="showFillUserData = false" />
  </Dialog>
</template>
<script setup>
import { Form, Field } from "vee-validate";
import * as yup from "yup";
import {
  ref,
  reactive,
  watchPostEffect,
  watch,
  defineAsyncComponent,
} from "vue";
import { useRouter } from "vue-router";
import { userStore } from "@/store/userStore";
import RegisterComponent from "./RegisterComponent";
import { useToast } from "primevue/usetoast";
import { useDialog } from "primevue/usedialog";
import FillUserDataForm from "./FillUserDataForm.vue";
import iAxios from "@/store/axiosInterface";
defineProps({
  visible: Boolean,
});
const dialog = useDialog();
const ResetPassword = defineAsyncComponent(() => import("./ResetPassword.vue"));
const toast = useToast();

const store = userStore();
const router = useRouter();

const resetPassShow = ref(false);
const showFillUserData = ref(false);

const resetPass = () => {
  showResetPass();
  toast.add({
    severity: "info",
    summary: "Passwort zurücksetzen",
    detail: "Bitte geben Sie Ihre E-Mail Adresse ein.",
    life: 3000,
  });
};

const setDebug = () => {
  const debug = store.debug;
  store.setDebug(!debug);
  toast.add({
    severity: store.debug ? "info" : "warn",
    summary: "Debug",
    detail: store.debug ? "Debug aktiviert" : "Debug deaktiviert",
    life: 3000,
  });
};

const showResetPass = () => {
  dialog.open(ResetPassword, {
    props: {
      header: "Password zurücksetzen",
      style: {
        width: "35vw",
      },
      breakpoints: {
        "960px": "55vw",
        "640px": "75vw",
      },
      modal: true,
    },
    onClose: (options) => {
      const data = options.data;
      if (data) {
        const buttonType = options.data.buttonType;
        if (buttonType == "ResetSend") {
          toast.add({
            severity: "success",
            summary: "Erfolg",
            detail:
              "Passwort zurücksetzen erfolgreich, bitte prüfen Sie Ihre Email und folgen Sie den Anweisungen.",
            life: 3000,
          });
        }
      }
    },
  });
};

const schema = yup.object().shape({
  email: yup
    .string()
    .required("E-Mail Adresse wird benötigt.")
    .trim()
    .email("Keine gültige E-Mail Adresse."),
  password: yup
    .string()
    .required("Password wird benötigt.")
    .min(6, "Password mindestens 6 Zeichen"),
});

const form = reactive({
  email: "",
  password: "",
  cid: "",
});
const error = ref("");
const isLoading = ref(false);

const errorDisplayText = () => {
  console.log(error.value);
  if (error.value) {
    if (error.value.includes("WRONG_PASSWORD")) {
      return "Passwort nicht korrekt.";
    } else if (error.value.includes("USER_NOT_FOUND")) {
      return "E-Mail Adresse nicht gefunden.";
    }
    return "Ein unbekannter Fehler, versuche es nochmal.";
  }
  return "";
};
const emit = defineEmits(["hide","updateCategory"]);

const userId = ref("");

watchPostEffect(() => {
  userId.value = store.userId; // Überwache die Änderungen von store.userId
});

const tipEnabledList = store.tipEnabledList;

watch(userId, () => {
  /*   if (store.isAuthenticated === true) {
      //store.whoAmI;
      if (store.firstLogin === true) {
        router.push("/companySettingsForm");
      } else {
        store.setRegistrationConfirmed(false);
        !tipEnabledList?.tipsList?.hasOwnProperty("fastStartVisible") || tipEnabledList?.tipsList?.["fastStartVisible"] ?
          router.push("/faststart") : router.push("/dashboard");
      }
    } */
});

const submitData = async () => {
  toast.removeAllGroups();
  isLoading.value = true;
  store.expired = false;
  error.value = "";
  const payload = {
    email: form.email,
    password: form.password,
    cid: store.companySettings.cid,
  };
  try {
    await schema.validate(payload);
    const response = await store.signin(payload);
    isLoading.value = false;
    //store.whoAmI();
    if (store?.userData.firstLogin == true) {
      showFillUserData.value = true;
      //router.push("/fillUserDataForm");

      /*       let url="/customer/itemlist/"+store.companySettings.cid;
  //if (store.userData) url="/auth/customer/itemlist";
  iAxios.get(url).then((response) => {
    console.log("🚀 ~ file: Welcome.vue:82 ~ response:", response)
    store.products = response.data.itemList;
  }); */
    } 
      emit("updateCategory");
      emit("hide");
  } catch (error) {
    error.value = error.message;
    console.log(
      "🚀 ~ file: TheLogin.vue:226 ~ submitData ~ error.message:",
      error.value
    );
    if (error.value == "CONFIRM_EMAIL") {
      toast.add({
        severity: "error",
        summary: "Email Validierung",
        detail:
          "Bitte vorerst die Email bestätigen, schauen Sie auch in Ihrem Spam Ordner",
        life: 3000,
      });
    } else if (
      error.value == "USER_NOT_FOUND" ||
      error.value == "WRONG_PASSWORD"
    ) {
      toast.add({
        severity: "error",
        summary: "Eingabe Fehler",
        detail: "Benutzername oder Passwort Fehlerhaft.",
        life: 3000,
      });
    } else {
      toast.add({
        severity: "error",
        summary: "Eingabe Fehler",
        detail: "Benutzername oder Passwort Fehlerhaft.",
        life: 3000,
      });
      console.error(error);
      console.log("🚀 ~ file: TheLogin.vue:239 ~ submitData ~ error:", error);
    }
    isLoading.value = false;
  }
};
</script>

<style scoped>
.font-medium {
  font-weight: 500 !important;
}
</style>
